/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

@include mat.core();

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

::-webkit-scrollbar {
    width: 3px;
}
::-webkit-scrollbar-track {
    background: transparent;
}
::-webkit-scrollbar-thumb {
    background-color: rgba(155, 155, 155, 0.5);
    border-radius: 20px;
    border: transparent;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

:root {
    --fuse-vertical-navigation-width: 280px;
}

.dark {
    --fuse-bg-default-rgb: 15,23,42;
}

.light {
    --fuse-bg-default-rgb: 100,255,255;
}

// Poprawka nieprawidłowego wyświetlania focus'a gdy jednocześnie korzystamy z Angular Material i Tailwind
.mdc-notched-outline__notch {
    border-right: none;
}

/* MENU */
.fap-sp-main-menu li {
    @apply mb-1
}

.fap-sp-main-menu li > a {
    @apply text-base flex items-center p-2 border border-transparent rounded text-gray-300
}

.fap-sp-main-menu li > a.fap-sp-router-link-active {
    background-color: rgba(255,255,255,0.1);
    @apply text-gray-100
}

.fap-sp-main-menu li > a > svg {
    @apply mr-2 text-gray-400 flex-none
}

/* TABLE */
.table th, 
.table td {
    @apply border border-gray-300 mb-4 p-2
}

.table td {
    @apply text-base
}

.table td.fap-sp-no-border,
.table th.fap-sp-no-border {
    @apply border-0
}

.table th {
    @apply bg-gray-100 h-14
}

.table td.fap-sp-no-background,
.table th.fap-sp-no-background {
    @apply bg-transparent
}

td.fap-lp {
    @apply bg-emerald-700  text-white
}

.fap-subhead td {
    @apply bg-gray-100 text-center text-sm font-bold p-2 h-14
}

.show-details {
    @apply hidden absolute inset-0  justify-center items-center
}

.protocol:hover .show-details {
    @apply flex
}

button[mat-button],
button[mat-flat-button] {
    $large-size-button: 3rem;
    $large-size-icon: 48px;
    
    &.button-large {
        min-width: $large-size-button;
        height: $large-size-button;
        line-height: $large-size-button;
        
        .mat-icon {
            font-size: $large-size-icon;
            width: $large-size-icon;
            height: $large-size-icon;
            line-height: $large-size-icon;
        }
        
        .mat-button-ripple {
            font-size: inherit;
            width: inherit;
            height: inherit;
            line-height: inherit;
        }
    }
    
    $small-size-button: 24px;
    $small-size-icon: 18px;

    &.icon-button-small {
        width: $small-size-button;
        height: $small-size-button;
        line-height: $small-size-button;

        .mat-icon {
            font-size: $small-size-icon;
            width: $small-size-icon;
            height: $small-size-icon;
            line-height: $small-size-icon;
        }
        .mat-button-ripple {
            font-size: inherit;
            width: inherit;
            height: inherit;
            line-height: inherit;
        }
    }
}

/* DIALOG */
.dialog-header {
    background-color: rgb(var(--fuse-bg-default-rgb));
    @apply border-b border-gray-300
}

h1.dialog-header.mat-mdc-dialog-title {
    @apply text-gray-300
}